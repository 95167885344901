// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

import React from "react"

import { NavProvider } from "../web/src/utils/navContext"
export const wrapRootElement = ({ element }) => (
  <NavProvider>{element}</NavProvider>
)
