import React from "react"

const defaultState = {
  dark: false,
  toggleDark: () => {},
  navLinks: [],
  setNavLinks: () => {},
  currentPage: null,
  setCurrentPage: () => {},
}

const NavContext = React.createContext(defaultState)

// Getting dark mode information from OS!
// You need macOS Mojave + Safari Technology Preview Release 68 to test this currently.
const supportsDarkMode = () =>
  window.matchMedia("(prefers-color-scheme: dark)").matches === true

class NavProvider extends React.Component {
  state = {
    dark: false,
    navLinks: [],
    currentPage: null,
  }
  toggleDark = () => {
    let dark = !this.state.dark
    localStorage.setItem("dark", JSON.stringify(dark))
    this.setState({ dark })
  }
  setNavLinks = navLinks => {
    this.setState({ navLinks })
  }
  setCurrentPage = currentPage => {
    this.setState({ currentPage })
  }
  componentDidMount() {
    // Getting dark mode value from localStorage!
    const lsDark = JSON.parse(localStorage.getItem("dark"))
    if (lsDark) {
      this.setState({ dark: lsDark })
    } else if (supportsDarkMode()) {
      this.setState({ dark: true })
    }
  }
  render() {
    const { children } = this.props
    const { dark, currentPage, navLinks } = this.state
    return (
      <NavContext.Provider
        value={{
          dark,
          toggleDark: this.toggleDark,
          navLinks: navLinks,
          setNavLinks: this.setNavLinks,
          currentPage,
          setCurrentPage: this.setCurrentPage,
        }}
      >
        {children}
      </NavContext.Provider>
    )
  }
}
export default NavContext
export { NavProvider }
